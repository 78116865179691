<template>
  <div class="container-recommendedItemall">
    <div class="auctionhome-header">
      <p>{{total}}个拍品</p>
      <div id="components-pagination-demo-mini">
        <a-pagination size="small" :total="total" @change="onChange" :pageSize='recentBrowseData.pageSize'
          v-model='recentBrowseData.page' />
      </div>
      <div class="auctionhome-header-right">
        <span>每页显示</span>
        <a-select placeholder="40" style="width: 120px" v-model="value2" @change="handleChange">
          <a-select-option :value="item.num" v-for="(item,index) in pageSizeNum" :key="index">
            {{item.num}}
          </a-select-option>
        </a-select>

      </div>
    </div>
    <div class="componentsDisplay">
      <!-- 左侧组件 -->
      <!-- <RecommendedItemchoose v-on:emitData='showMsgfromChild' /> -->
      <!--右侧组件 -->
      <RecommendedItemchoose v-on:emitData='showMsgfromChild' :attributeListA='attributeListA'
        :brandAuthorIdA='brandAuthorIdA' :stylePeriodIdA='stylePeriodIdA' :materialsCraftIdA='materialsCraftIdA'
        :placeSourceIdA='placeSourceIdA' :auctionHouseIdA='auctionHouseIdA' :twoClassifyIdB='twoClassifyIdB'
        :auctionHouseIdSearch='auctionHouseIdSearch' />
      <!--右侧组件 -->
      <RecommendedIteright :pageSize='recentBrowseData.pageSize' :page='recentBrowseData.page' />
    </div>
    <div class="auctionhome-header border-top-line">
      <p>{{total}}个拍品</p>
      <div id="components-pagination-demo-mini" @click="goTop">
        <a-pagination size="small" :total="total" @change="onChange" v-model="recentBrowseData.page"
          :pageSize="recentBrowseData.pageSize" />
      </div>
      <div class="auctionhome-header-right">
        <span style="font-size:18.5px">每页显示</span>
        <a-select placeholder="40" style="width: 120px" v-model="value2" @change="handleChange">
          <a-select-option :value="item.num" v-for="(item,index) in pageSizeNum" :key="index">
            {{item.num}}
          </a-select-option>
        </a-select>

      </div>
    </div>
  </div>
</template>
<script>
import RecommendedItemchoose from './components/RecommendedItemchoose.vue'
import RecommendedIteright from './components/RecommendedIteright.vue'
import { recentBrowse, classifyItemAttribute } from '../../api/index'
import eventBus from '../../utils/eventBus'
export default {
  name: 'RecommendedItemall',
  components: {
    RecommendedItemchoose,
    RecommendedIteright

  },
  data () {
    return {
      isChoose: false,
      value: '',
      recentBrowseData: {
        userid: 0,
        page: 1,
        pageSize: 40,
        auctionWayId: '',
        brandAuthorId: '',
        stylePeriodId: '',
        materialsCraftId: '',
        placeSourceId: '',
        auctionHouseId: ''
      },
      attributeListA: {},
      brandAuthorIdA: {},
      stylePeriodIdA: {},
      materialsCraftIdA: {},
      placeSourceIdA: {},
      auctionHouseIdA: {},
      classifyIdA: {},
      twoClassifyIdB: {},
      attributeListShow: null,
      auctionHouseIdSearch: {},
      total: 0,
      recentBrowseList: [],
      pageSizeNum: [
        {
          id: 1,
          num: 40
        },
        {
          id: 2,
          num: 80
        },
        {
          id: 3,
          num: 120
        }
      ],
      value2: 40,
      top: 0,
      gotop: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  created () {
    if (sessionStorage.getItem('msgInfo14')) {
      this.recentBrowseData.page = Number(sessionStorage.getItem('currentPage14')) || 1
      this.recentBrowseData.pageSize = Number(sessionStorage.getItem('select14')) || 40
      this.value2 = Number(sessionStorage.getItem('select14')) || 40
    } else {
      sessionStorage.removeItem('currentPage14')
      sessionStorage.removeItem('select14')
      this.recentBrowseData.page = 1
      this.recentBrowseData.pageSize = 40
      this.value2 = 40
    }
    this.recentBrowse()
  },
  destroyed () {
    sessionStorage.removeItem('msgInfo14')
  },
  methods: {
    toggePay () {
      this.isChoose = !this.isChoose
    },
    handleScroll (e) {
      const scrolltop = e.target.scrollTop
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false)
    },
    goTop () {
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = this.top -= 50
        if (this.top <= 0) {
          clearInterval(timeTop)
        }
      }, 0)
    },

    // 获取页面滚动高度
    scrollHandle (e) {
      this.top = e.srcElement.scrollingElement.scrollTop // 获取页面滚动高度
    },
    handleChange (value) {
      this.recentBrowseData.page = 1
      this.recentBrowseData.pageSize = value
      this.recentBrowse()
    },
    // 子组件的参数
    showMsgfromChild (data) {
      this.recentBrowseData.auctionWayId = data.auctionWayId
      this.recentBrowseData.brandAuthorId = data.brandAuthorId
      this.recentBrowseData.stylePeriodId = data.stylePeriodId
      this.recentBrowseData.materialsCraftId = data.materialsCraftId
      this.recentBrowseData.placeSourceId = data.placeSourceId
      this.recentBrowseData.auctionHouseId = data.auctionHouseId
      this.recentBrowse()
    },
    async recentBrowse () {
      const res = await recentBrowse(this.recentBrowseData)
      if (res.code === 0) {
        this.total = res.data.count
        eventBus.$emit('recentBrowseList', res.data.list)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 分页
    onChange (page) {
      this.recentBrowseData.page = page
      this.recentBrowse()
    }
  }
}
</script>
<style lang="less" scoped>
.container-recommendedItemall {
  /deep/ .ant-select-selection__placeholder {
    color: rgba(0, 0, 0, 0.65);
  }
  /deep/ .ant-pagination-item-active {
    border: none;
  }
  #components-pagination-demo-mini .ant-pagination:not(:last-child) {
    margin-bottom: 24px;
  }
  /deep/ .ant-pagination-item-active a {
    color: #cda156;
  }
  /deep/ .ant-pagination-item a:hover {
    color: #cda156;
  }
  /deep/ .ant-pagination-item {
    font-size: 16px;
  }
  /deep/ .ant-select {
    width: 80px !important;
  }
  /deep/ .ant-pagination-item-active a {
    color: #cda156;
  }
  /deep/ .ant-pagination-item a:hover {
    color: #cda156;
  }
  /deep/ .ant-pagination-item {
    font-size: 17px;
  }
  /deep/ .ant-pagination-item-active a {
    color: #fff;
    background: #cda156;
  }
  /deep/ .ant-pagination-item a:hover {
    color: #000;
  }
  /deep/ .ant-pagination-item {
    border: 1px solid #ccc !important;
    margin: 0px 5px;
    a {
      font-size: 15px;
    }
  }
  /deep/ .ant-pagination-prev {
    border: 1px solid #ccc !important;
    margin-right: 5px;
  }
  /deep/ .ant-pagination-next {
    border: 1px solid #ccc !important;
    margin-left: 5px;
  }
  /deep/ .anticon {
    vertical-align: 0;
  }
  /deep/ .ant-pagination-item-active {
    background: #cda156;
  }
  .auctionhome-header {
    height: 70px;
    align-items: center;
    display: flex;
    margin: 0 100px;
    justify-content: space-between;
    border-bottom: 1px solid #dedede;
    p:nth-child(1) {
      font-size: 20px;
      font-weight: 400;
      color: #333;
      margin-bottom: 0;
    }
    .auctionhome-header-right {
      display: flex;
      align-items: center;

      span {
        font-size: 18.5px;
        color: #333;
        font-weight: 400;
        padding-right: 10px;
      }

      p:nth-child(2) {
        span {
          font-size: 20px;
          font-weight: 400;
          color: #333;
          padding-right: 10px;
        }
        a {
          color: #333;
          i {
            font-size: 20px;
          }
        }
      }
    }
  }
  .border-top-line {
    border-top: 1px solid #dedede;
  }
  .componentsDisplay {
    display: flex;
  }
}
</style>
