<template>
  <div class="container-recommendedIteright">
    <div class="recommended-item-img">
      <div v-for="(item,index) in recentBrowseListValue" :key="index">
        <div class="recommended-item-img-top">
          <div class="love" :class="[!item.status?'love':start]" @click="tabImgColor(item.id,index,item.status)"></div>

          <!-- <div class="love" @click="tabImgColor(item.id)"></div> -->
          <!-- <div :style="{background:'url('+item.hostImage+')' } " class="forBackgroundImage"
            @click="allProDetile(item.id)"></div> -->
          <div class="col-md-3">
            <div class="thumb-container">
              <div class="thumb">
                <a href="javascript:;" @click="allProDetile(item.id)">
                  <img :src="item.hostImage" alt="">
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="recommended-item-img-bottom">

          <a href="javascript:;" :title="item.title" @click="allProDetile(item.id)"
            class="bottom-dd">Lot&nbsp;{{item.lot}}：{{item.title}}</a>

          <a class="auctionHouseName-a" href="javascript:;"
            @click="auctionHouseName(item.auctionHouseId)">{{item.auctionHouseName}}</a>
          <p>
            <span>{{item.auctionTime}}</span>
            <span>|</span>
            <span>北京时间</span>
          </p>
          <p>起拍价格：{{item.initialPrice}}&nbsp;{{item.currency}}</p>
        </div>
      </div>
    </div>
    <Login ref="login" />
  </div>
</template>
<script>
import eventBus from '../../../utils/eventBus'
import Login from '../../../components/login.vue'
import { userCollect } from '../../../api/index'
export default {
  name: 'recommendedIteright',
  components: {
    Login
  },
  data () {
    return {
      recentBrowseListValue: [],
      searchShow: true,
      start: 'active'
    }
  },
  props: {
    pageSize: {
      type: Number
    },
    page: {
      type: Number
    }
  },
  mounted () {
    eventBus.$on('recentBrowseList', (value) => {
      this.recentBrowseListValue = value
    })
  },
  methods: {
    tabImgColor (id, index, status) {
      if (!sessionStorage.getItem('accessToken')) {
        // this.$message.error('请登录')
        this.$nextTick(() => {
          this.$refs.login.open()
        })
      } else {
        if (status === true) {
          this.$message.error('取消收藏')
        } else {
          this.$message.success('收藏成功')
        }
        this.recentBrowseListValue[index].status = !this.recentBrowseListValue[index].status
        this.userCollect(id)
      }
    },
    // 点击收藏
    async userCollect (id) {
      const res = await userCollect({
        userid: this.$store.state.userId,
        itemid: id
      })
      if (res.code === 0) {
        // this.$message.success('收藏成功')
      } else {
        // this.$message.error('收藏失败')
      }
    },
    // 跳转产品详情
    allProDetile (id) {
      sessionStorage.setItem('currentPage14', this.page)
      sessionStorage.setItem('select14', this.pageSize)
      this.$router.push({
        path: '/productdetails',
        name: 'Productdetails',
        query: {
          productdetailsId: id
        }
      })
    },
    // 跳转拍卖行
    auctionHouseName (id) {
      this.$router.push({
        path: '/auctionhousedetails',
        name: 'Auctionhousedetails',
        query: {
          auctionhousedetailsid: id
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container-recommendedIteright {
  // display: flex;

  width: 1728px;

  .recommended-item-img {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    div:hover {
      box-shadow: 2px 2px 20px 2px rgba(46, 46, 46, 0.1);
    }
    div {
      width: 315px;
      // height: 417px;
      margin: 20px;
      // display: inline-block;
      box-shadow: 0px 1px 16px 0px rgba(46, 46, 46, 0.05);
      background: #fff;
      .recommended-item-img-top {
        width: 100%;
        height: 315px;
        margin: 0;
        position: relative;
        overflow: hidden;
        .forBackgroundImage {
          background-size: 70% !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          width: 100%;
          // z-index: -1;
          margin: 0;
          opacity: 1;
          height: 100%;
        }
        .col-md-3 {
          width: 100%;
          margin: 0;
          border: none;
          height: none;
          .thumb-container {
            width: 100%;
            // height: 317px;
            position: relative;
            padding-bottom: 100%;
            margin: 0;
            border: none;
            .thumb {
              position: absolute;
              width: 100%;
              height: 100%;
              text-align: center;
              margin: 0;
              border-top: none;
              border-bottom: none;
              border-left: none;
              a {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                max-width: 100%;
                max-height: 100%;
                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            }
          }
        }
        .love:hover {
          background: url("../../../assets/images/17.png") no-repeat;
          background-size: 40px;
        }
        .love {
          position: absolute;
          width: 40px;
          height: 40px;
          top: 10px;
          right: 10px;
          margin: 0;
          background: url("../../../assets/images/9.png") no-repeat;
          background-size: 40px;
          border: transparent;
          border-radius: 50%;
          z-index: 10;
        }
        .active {
          background: url("../../../assets/images/17.png") no-repeat !important;
          background-size: 40px !important;
          position: absolute;
          width: 40px;
          height: 40px;
          top: 10px;
          right: 10px;
          margin: 0;
          background-size: 40px;
          border: transparent;
          border-radius: 50%;
        }
        // img {
        //   width: 100%;
        // }
      }
      .recommended-item-img-bottom {
        width: 100%;
        // height: 230px;
        margin: 0;
        box-sizing: border-box;
        padding: 0 30px;
        text-align: left;
        margin-top: 10px;
        .bottom-dd {
          font-size: 20px;
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /*2行末尾显示点点点*/
        }
        p {
          margin-bottom: 0;
        }
        a {
          font-size: 20px;
          font-weight: 600;
          height: 63px;
          color: #333;

          &:hover {
            text-decoration: underline;
          }
        }
        .auctionHouseName-a {
          font-size: 18px;
          font-weight: 400;
          color: #2c3e50;
        }

        p:nth-child(3) {
          color: #666;
          font-size: 18px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-bottom: 10px;
          padding-top: 10px;
          span:nth-child(2) {
            padding: 0 10px;
          }
        }
        p:nth-child(4) {
          font-weight: 500;
          font-size: 18px;
          color: #333;
          padding-bottom: 20px;
        }
      }
    }
  }
}
</style>
